<dx-popup [width]="1200" maxHeight="98vh" height="auto" overflow="auto" [dragEnabled]=true [resizeEnabled]=true
    maxWidth="90%" [(visible)]=isIncidentLogPopupVisible [(title)]="formTitle">
    <div style="padding: 4px;">
        <dx-form labelLocation="left" colCount="1">
            <dxi-item itemType="group">
                <div style="border: 1px solid rgb(88, 90, 121); background: transparent;">
                    <dx-data-grid [dataSource]="logDatasource" [showBorders]="true" [showColumnLines]="false"
                    [allowColumnResizing]="true" [showRowLines]="true" [rowAlternationEnabled]="true" [allowColumnReordering]="true"
                    [columnChooser]="{enabled: false}" height="45vh" >
                    <dxi-column dataField="Action" caption="Action" [width]="120"></dxi-column>  
                    <dxi-column dataField="Description" caption="Description"></dxi-column>          
                    <dxi-column dataField="ActionDate" caption="ActionDate" dataType="date" format="dd-MMM-yyyy HH:mm" [width]="130"></dxi-column>
                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                    <dxo-search-panel [visible]="false"></dxo-search-panel>
                    <dxo-selection mode="single"></dxo-selection>
                </dx-data-grid>
                </div>
            </dxi-item>
        </dx-form>
        <dx-toolbar style="margin-top: 3px;">
               <dxi-item widget="dxButton" locateInMenu="auto" location="after">
                <dx-button (click)="viewLogCloseClick()" type="normal" width="100" height="25" text="Cancel"
                    icon="close">
                </dx-button>
            </dxi-item>
        </dx-toolbar>
    </div>
</dx-popup>

<message-dialog></message-dialog>
<processing-dialog></processing-dialog>

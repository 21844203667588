import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControllerService } from 'src/app/services/common/controller-service';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isLogOutPopupVisible: Boolean = false;
  isOpen: boolean;
  fullName: String = sessionStorage.getItem("FullName");
  profilePicture: String = sessionStorage.getItem("PP");

  constructor(private router: Router,
    private controllerService: ControllerService) { }

  ngOnInit() {
    this.fullName = sessionStorage.getItem("FullName");
    this.profilePicture=sessionStorage.getItem("PP");
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logOutClick() {
    this.isOpen = false;
    this.isLogOutPopupVisible = true;
  }

  confirmLogoutClick() {
    this.controllerService.utilitiesGet("Users/LogOut?userID=" + sessionStorage.getItem("UserID") + "&type=User&save=true&save1=true&save2=true&save3=true").subscribe();
    sessionStorage.clear();
    sessionStorage.setItem("Token", "");
    this.router.navigate(['/', 'login']);
  }

  closeConfirmLogOutClick() {
    this.isLogOutPopupVisible = false;
  }

}

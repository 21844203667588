<div class="search-wrapper"
     fxLayout="row"
     [ngClass]="{ 'focus': focused, 'has-input': input }"
     fxFlex
     (furyClickOutside)="closeDropdown()">

        <span fxLayout="row" fxLayoutAlign="start center">
          <img style="width: 40px;" [src]="sanitizedLogoData">
          <span class="name" fxHide fxShow.gt-sm style="color: rgb(88, 90, 121);"><b><h3>{{organisationName}}</h3></b></span>          
        </span>

  <div class="search-dropdown">
    <div class="content results"
         *ngIf="input"
         fxLayout="column">
      <div class="heading"
           fxLayout="row"
           fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        Search results for: {{ input }}
      </div>
      <div class="items">
        <div class="item"
             matRipple
             [routerLink]="['/forms/form-elements']">Form Elements
        </div>
        <div class="item"
             matRipple
             [routerLink]="['/forms/form-wizard']">Form Wizard
        </div>
        <div class="item"
             matRipple
             [routerLink]="['/editor']">WYSIWYG Editor
        </div>
        <div class="item"
             matRipple
             [routerLink]="['/apps/inbox']">Inbox
        </div>
        <div class="item"
             matRipple
             [routerLink]="['/components/dialogs']">Material Dialog
        </div>
      </div>
    </div>

    <div class="content recents"
         fxLayout="row"
         *ngIf="!input">
      <div class="recently"
           fxLayout="column"
           fxFlex>
        <div class="heading"
             fxLayout="row"
             fxLayoutAlign="start center">
          <mat-icon class="icon">restore</mat-icon>
          Recently Visited
        </div>
        <div class="items">
          <div class="item"
               matRipple
               *ngFor="let item of recentlyVisited"
               [routerLink]="item.routeOrFunction"
               (click)="closeDropdown()">{{ item.name }}
          </div>
        </div>
      </div>
      <div class="frequently"
           fxLayout="column"
           fxFlex
           fxFlexOffset="16px">
        <div class="heading"
             fxLayout="row"
             fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          Frequently Visited
        </div>
        <div class="items">
          <div class="item"
               matRipple
               [routerLink]="['/forms/form-elements']"
               (click)="closeDropdown()">Form Elements
          </div>
          <div class="item"
               matRipple
               [routerLink]="['/forms/form-wizard']"
               (click)="closeDropdown()">Form Wizard
          </div>
          <div class="item"
               matRipple
               [routerLink]="['/editor']"
               (click)="closeDropdown()">WYSIWYG Editor
          </div>
          <div class="item"
               matRipple
               [routerLink]="['/apps/inbox']"
               (click)="closeDropdown()">Inbox
          </div>
          <div class="item"
               matRipple
               [routerLink]="['/components/dialogs']"
               (click)="closeDropdown()">Material Dialog
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

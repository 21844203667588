<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow"> 
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" [src]="profilePicture">
      <span class="name" fxHide fxShow.gt-sm style="color: rgb(88, 90, 121);">{{fullName}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple [routerLink]="['/home']">
          <mat-icon class="list-item-icon">home</mat-icon>
          <span>Home</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple [routerLink]="['/my-profile']">
          <mat-icon class="list-item-icon">person_pin</mat-icon>
          <span>My Profile</span>
        </div>
        <div class="list-item" matRipple [routerLink]="['/out-of-office']">
          <mat-icon class="list-item-icon">schedule</mat-icon>
          <span>Out Of Office</span>
        </div>       
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="logOutClick()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Log Out</span>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup maxWidth="500px" maxHeight="98vh" height="auto" overflow="auto" dragEnabled="true"
    [(visible)]="isLogOutPopupVisible" title="Log Out">
    <div>
        <div class="dialogIcon">
            <div class="question-icon"></div>
        </div>
        <span style="display:inline">
            <div style="font-size:15px;padding-top: 6px;">
                Are you sure you want to log out of the system?
            </div>
        </span>
    </div>
    <br>
    <dx-toolbar>
        <dxi-item widget="dxButton" locateInMenu="auto" location="after">
            <dx-button (click)="confirmLogoutClick()" type="normal" width="100" height="25" text="Yes" icon="check">
            </dx-button>
        </dxi-item>
        <dxi-item widget="dxButton" locateInMenu="auto" location="after">
            <dx-button (click)="closeConfirmLogOutClick()" type="normal" width="100" height="25" text="Cancel" icon="close">
            </dx-button>
        </dxi-item>
    </dx-toolbar>
</dx-popup>

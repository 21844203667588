<div *ngIf="visible$ | async" class="footer" fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px" fxFlex="grow">
    <!-- <a href="https://1.envato.market/LPZQEV"
       class="action" color="accent" fxFlex="none" id="get-fury" mat-raised-button>
      <mat-icon class="icon">shopping_cart</mat-icon>
      Buy Fury (Angular 16+)
    </a> -->


    <div class="name" fxHide fxShow.gt-sm style="color: rgb(88, 90, 121);">
      Care Harbour System Powered By Dev @2023
    </div>
  </div>
</div>

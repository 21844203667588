import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  userMenu: any;
  constructor(private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    private splashScreenService: SplashScreenService) {
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }



    // this.sidenavService.addItems([
    //   {
    //     name: 'SYSTEM SET UP',
    //     type: 'subheading',
    //     position: 5
    //   },
    //   {
    //     name: 'System Settings',
    //     icon: 'settings',
    //     position: 5,
    //     subItems: [
    //       {
    //         name: 'Organisation Details',
    //         routeOrFunction: '/organisation-details',
    //         position: 5
    //       },
    //       {
    //         name: 'Organisation Set Up',
    //         routeOrFunction: '/organisation-set-up',
    //         position: 6
    //       },
    //       {
    //         name: 'Numbering',
    //         routeOrFunction: '/numbering',
    //         position: 10
    //       },
    //       {
    //         name: 'Parameters',
    //         routeOrFunction: '/parameters',
    //         position: 10
    //       },
    //       {
    //         name: 'Public Holidays',
    //         routeOrFunction: '/public-holidays',
    //         position: 15
    //       },
    //       {
    //         name: 'Document Types',
    //         routeOrFunction: '/document-types',
    //         position: 15
    //       }
    //     ]
    //   },
    //   {
    //     name: 'Workflow',
    //     icon: 'shuffle',
    //     position: 10,
    //     subItems: [
    //       {
    //         name: 'Settings',
    //         routeOrFunction: '/workflow-settings',
    //         position: 10
    //       },
    //       {
    //         name: 'Incident Types',
    //         routeOrFunction: '/incident-types',
    //         position: 10
    //       },
    //       {
    //         name: 'Email Notifications',
    //         routeOrFunction: '/workflow-email-notifications',
    //         position: 15
    //       },
    //       {
    //         name: 'SMS Notifications',
    //         routeOrFunction: '/workflow-sms-notifications',
    //         position: 15
    //       },
    //       {
    //         name: 'Work Days',
    //         routeOrFunction: '/work-days',
    //         position: 15
    //       },
    //       {
    //         name: 'Priorities',
    //         routeOrFunction: '/priorities',
    //         position: 15
    //       },
    //       {
    //         name: 'Rejection Reasons',
    //         routeOrFunction: '/rejection-reasons',
    //         position: 15
    //       },
    //       {
    //         name: 'Escalation Groups',
    //         routeOrFunction: '/escalation-groups',
    //         position: 15
    //       },
    //       {
    //         name: 'Workflow Steps',
    //         routeOrFunction: '/steps',
    //         position: 15
    //       },
    //       {
    //         name: 'Workflows',
    //         routeOrFunction: '/workflows',
    //         position: 15
    //       }
    //     ]
    //   },
    //   {
    //     name: 'Communication',
    //     icon: 'forum',
    //     position: 15,
    //     subItems: [
    //       {
    //         name: 'Email Settings',
    //         routeOrFunction: '/email-settings',
    //         position: 5
    //       },
    //       {
    //         name: 'Email Templates',
    //         routeOrFunction: '/email-templates',
    //         position: 15
    //       },
    //       {
    //         name: 'SMS Settings',
    //         routeOrFunction: '/sms-settings',
    //         position: 20
    //       },
    //       {
    //         name: 'SMS Templates',
    //         routeOrFunction: '/sms-templates',
    //         position: 25
    //       }
    //     ]
    //   },


    //   {
    //     name: 'ADMINISTRATION',
    //     type: 'subheading',
    //     position: 30
    //   },
    //   {
    //     name: 'System Admin',
    //     icon: 'laptop_chromebook',
    //     position: 30,
    //     subItems: [
    //       {
    //         name: 'Manage Users',
    //         routeOrFunction: '/manage-users',
    //         position: 15
    //       },
    //       {
    //         name: 'User Profiles',
    //         routeOrFunction: '/user-profiles',
    //         position: 15
    //       },
    //       {
    //         name: 'User Groups',
    //         routeOrFunction: '/user-groups',
    //         position: 15
    //       },
    //       {
    //         name: 'Re-Assign Incidents',
    //         routeOrFunction: '/re-assign-incidents',
    //         position: 16
    //       },
    //       {
    //         name: 'Manage Documents',
    //         routeOrFunction: '/manage-documents',
    //         position: 17
    //       },
    //       {
    //         name: 'Audit Trail',
    //         routeOrFunction: '/audit-trail',
    //         position: 18
    //       },
    //     ]
    //   },

    //   {
    //     name: 'SYSTEM MODULES',
    //     type: 'subheading',
    //     position: 31
    //   },
    //   {
    //     name: 'Employees',
    //     icon: 'assignment_ind',
    //     position: 31,
    //     subItems: [
    //       {
    //         name: 'Payment Rates',
    //         routeOrFunction: '/payment-rates',
    //         position: 15
    //       },
    //       {
    //         name: 'Allowances',
    //         routeOrFunction: '/allowances',
    //         position: 15
    //       },
    //       {
    //         name: 'Employees',
    //         routeOrFunction: '/employees',
    //         position: 15
    //       },
    //     ]
    //   },

    //   {
    //     name: 'Clients',
    //     icon: 'airline_seat_recline_extra',
    //     position: 32,
    //     subItems: [
    //       {
    //         name: 'Clients',
    //         routeOrFunction: '/clients',
    //         position: 15
    //       },
    //     ]
    //   },

    //   {
    //     name: 'Scheduler',
    //     icon: 'access_alarms',
    //     position: 33,
    //     subItems: [
    //       {
    //         name: 'Calendar',
    //         routeOrFunction: '/clients',
    //         position: 15
    //       },
    //     ]
    //   },

    //   {
    //     name: 'ANALYTICS',
    //     type: 'subheading',
    //     position: 35
    //   },
    //   {
    //     name: 'Dashboards',
    //     icon: 'dashboard',
    //     position: 35,
    //     subItems: [
    //       {
    //         name: 'Clients',
    //         routeOrFunction: '/clients-dashboard',
    //         position: 15
    //       },
    //       {
    //         name: 'Employees',
    //         routeOrFunction: '/employees-dashboard',
    //         position: 15
    //       },
    //       {
    //         name: 'Workflows',
    //         routeOrFunction: '/workflows-dashboard',
    //         position: 15
    //       },
    //       {
    //         name: 'System Calendar',
    //         routeOrFunction: '/system-calendar',
    //         position: 15
    //       },
    //     ]
    //   },
    //   {
    //     name: 'Reports',
    //     icon: 'functions',
    //     position: 35,
    //     subItems: [
    //       {
    //         name: 'Workflows',
    //         routeOrFunction: '/workflows-dashboard',
    //         position: 15
    //       },
    //     ]
    //   },

    //   {
    //     name: 'USER PAGES',
    //     type: 'subheading',
    //     position: 65
    //   },
    //   {
    //     name: 'Incidents',
    //     icon: 'phone_forwarded',
    //     position: 65,
    //     subItems: [
    //       {
    //         name: 'Approval Requests',
    //         routeOrFunction: '/approval-requests',
    //         // badge: '0',
    //         // badgeColor: '#2196F3',
    //         position: 5
    //       },
    //       {
    //         name: 'My Open Incidents',
    //         routeOrFunction: '/open-incidents',
    //         position: 10
    //       },
    //       {
    //         name: 'My Closed Incidents',
    //         routeOrFunction: '/closed-incidents',
    //         position: 15
    //       }
    //     ]
    //   },

    //   {
    //     name: 'User Account',
    //     icon: 'person_outline',
    //     position: 66,
    //     subItems: [
    //       {
    //         name: 'My Profile',
    //         routeOrFunction: '/my-profile',
    //         position: 5
    //       },
    //       {
    //         name: 'Documents',
    //         routeOrFunction: '/documents',
    //         position: 5
    //       },
    //       {
    //         name: 'Out Of Office',
    //         routeOrFunction: '/out-of-office',
    //         position: 5
    //       }
    //     ]
    //   },

    // ]);
  }
  ngOnInit(): void {
    this.getMenu();
  }

  getMenu() {
    //this.sidenavService.addItems(this.userMenu);
    this.sidenavService.items=[];
    this.sidenavService.addItems([
      {
        name: 'SYSTEM SET UP',
        type: 'subheading',
        position: 5
      },
      {
        name: 'System Settings',
        icon: 'settings',
        position: 5,
        subItems: [
          {
            name: 'Organisation Details',
            routeOrFunction: '/organisation-details',
            position: 5
          },
          {
            name: 'Organisation Set Up',
            routeOrFunction: '/organisation-set-up',
            position: 6
          },
          {
            name: 'Numbering',
            routeOrFunction: '/numbering',
            position: 10
          },
          {
            name: 'Parameters',
            routeOrFunction: '/parameters',
            position: 10
          },
          {
            name: 'Public Holidays',
            routeOrFunction: '/public-holidays',
            position: 15
          },
          {
            name: 'Document Types',
            routeOrFunction: '/document-types',
            position: 15
          },
          {
            name: 'Plan Managers',
            routeOrFunction: '/plan-managers',
            position: 15
          }
        ]
      },
      {
        name: 'Workflow',
        icon: 'shuffle',
        position: 10,
        subItems: [
          {
            name: 'Settings',
            routeOrFunction: '/workflow-settings',
            position: 10
          },
          {
            name: 'Incident Types',
            routeOrFunction: '/incident-types',
            position: 10
          },
          {
            name: 'Email Notifications',
            routeOrFunction: '/workflow-email-notifications',
            position: 15
          },
          {
            name: 'SMS Notifications',
            routeOrFunction: '/workflow-sms-notifications',
            position: 15
          },
          {
            name: 'Work Days',
            routeOrFunction: '/work-days',
            position: 15
          },
          {
            name: 'Priorities',
            routeOrFunction: '/priorities',
            position: 15
          },
          {
            name: 'Rejection Reasons',
            routeOrFunction: '/rejection-reasons',
            position: 15
          },
          {
            name: 'Escalation Groups',
            routeOrFunction: '/escalation-groups',
            position: 15
          },
          {
            name: 'Workflow Steps',
            routeOrFunction: '/steps',
            position: 15
          },
          {
            name: 'Workflows',
            routeOrFunction: '/workflows',
            position: 15
          }
        ]
      },
      {
        name: 'Communication',
        icon: 'forum',
        position: 15,
        subItems: [
          {
            name: 'Email Settings',
            routeOrFunction: '/email-settings',
            position: 5
          },
          {
            name: 'Email Templates',
            routeOrFunction: '/email-templates',
            position: 15
          },
          {
            name: 'SMS Settings',
            routeOrFunction: '/sms-settings',
            position: 20
          },
          {
            name: 'SMS Templates',
            routeOrFunction: '/sms-templates',
            position: 25
          },
          {
            name: 'Notification Templates',
            routeOrFunction: '/notification-templates',
            position: 25
          }
        ]
      },


      {
        name: 'ADMINISTRATION',
        type: 'subheading',
        position: 30
      },
      {
        name: 'System Admin',
        icon: 'laptop_chromebook',
        position: 30,
        subItems: [
          {
            name: 'Manage Users',
            routeOrFunction: '/manage-users',
            position: 15
          },
          {
            name: 'User Profiles',
            routeOrFunction: '/user-profiles',
            position: 15
          },
          {
            name: 'User Groups',
            routeOrFunction: '/user-groups',
            position: 15
          },
          {
            name: 'Re-Assign Incidents',
            routeOrFunction: '/re-assign-incidents',
            position: 16
          },
          {
            name: 'Manage Documents',
            routeOrFunction: '/manage-documents',
            position: 17
          },
          {
            name: 'Audit Trail',
            routeOrFunction: '/audit-trail',
            position: 18
          },
        ]
      },

      {
        name: 'SYSTEM MODULES',
        type: 'subheading',
        position: 31
      },
      {
        name: 'Employees',
        icon: 'assignment_ind',
        position: 31,
        subItems: [
          {
            name: 'Payment Rates',
            routeOrFunction: '/payment-rates',
            position: 15
          },
          {
            name: 'Administration Payment Rates',
            routeOrFunction: '/administration-payment-rates',
            position: 15
          },
          {
            name: 'Allowances',
            routeOrFunction: '/allowances',
            position: 15
          },
          {
            name: 'Carer Groups',
            routeOrFunction: '/carer-groups',
            position: 15
          },
          {
            name: 'Employees',
            routeOrFunction: '/employees',
            position: 15
          },
          {
            name: 'Employee Updates',
            routeOrFunction: '/employee-updates',
            position: 15
          },
        ]
      },

      {
        name: 'Participants',
        icon: 'airline_seat_recline_extra',
        position: 32,
        subItems: [
          {
            name: 'Participant Groups',
            routeOrFunction: '/participant-groups',
            position: 15
          },
          {
            name: 'Participants',
            routeOrFunction: '/participants',
            position: 15
          },
          {
            name: 'Participant Updates',
            routeOrFunction: '/participant-updates',
            position: 15
          },
        ]
      },

      {
        name: 'Roaster',
        icon: 'access_alarms',
        position: 33,
        subItems: [
          {
            name: 'Parameters',
            routeOrFunction: '/roaster-parameters',
            position: 15
          },
          {
            name: 'Communication Set Up',
            routeOrFunction: '/roaster-communication-setup',
            position: 15
          },
          {
            name: 'Shift Type Times',
            routeOrFunction: '/shift-times',
            position: 15
          },
          {
            name: 'Participant Master Shifts',
            routeOrFunction: '/participant-master-shifts',
            position: 15
          },
          {
            name: 'Shift Planning',
            routeOrFunction: '/shift-planning',
            position: 15
          },
          {
            name: 'Shift Offers',
            routeOrFunction: '/shift-offers',
            position: 15
          },
          {
            name: 'Shifts',
            routeOrFunction: '/shifts',
            position: 15
          },
          {
            name: 'Timesheets',
            routeOrFunction: '/timesheets',
            position: 15
          },
          {
            name: 'Unavailability Requests',
            routeOrFunction: '/unavailability-requests',
            position: 15
          },
        ]
      },

      {
        name: 'Accounting',
        icon: 'attach_money',
        position: 34,
        subItems: [
          {
            name: 'Timesheet Batches',
            routeOrFunction: '/timesheet-batches',
            position: 15
          },
          {
            name: 'Invoices',
            routeOrFunction: '/invoices',
            position: 15
          }
        ]
      },

      {
        name: 'ANALYTICS',
        type: 'subheading',
        position: 35
      },
      {
        name: 'Dashboards',
        icon: 'dashboard',
        position: 35,
        subItems: [
          {
            name: 'Participants',
            routeOrFunction: '/participants-dashboard',
            position: 15
          },
          {
            name: 'Employees',
            routeOrFunction: '/employees-dashboard',
            position: 15
          },
          {
            name: 'Workflows',
            routeOrFunction: '/workflows-dashboard',
            position: 15
          },
          {
            name: 'Timesheets',
            routeOrFunction: '/timesheets-dashboard',
            position: 15
          },
          {
            name: 'System Calendar',
            routeOrFunction: '/system-calendar',
            position: 15
          },
          {
            name: 'Shifts Calendar',
            routeOrFunction: '/shifts-calendar',
            position: 15
          },
          // {
          //   name: 'Shifts Status',
          //   routeOrFunction: '/shifts-status',
          //   position: 15
          // },
          {
            name: 'Participants Map',
            routeOrFunction: '/participants-map',
            position: 15
          },
        ]
      },
      {
        name: 'Reports',
        icon: 'functions',
        position: 35,
        subItems: [
          {
            name: 'Workflows',
            routeOrFunction: '/workflows-dashboard',
            position: 15
          },
        ]
      },

      {
        name: 'USER PAGES',
        type: 'subheading',
        position: 65
      },
      {
        name: 'Incidents',
        icon: 'phone_forwarded',
        position: 65,
        subItems: [
          {
            name: 'Approval Requests',
            routeOrFunction: '/approval-requests',
            // badge: '0',
            // badgeColor: '#2196F3',
            position: 5
          },
          {
            name: 'My Open Incidents',
            routeOrFunction: '/open-incidents',
            position: 10
          },
          {
            name: 'My Closed Incidents',
            routeOrFunction: '/closed-incidents',
            position: 15
          }
        ]
      },

      {
        name: 'User Account',
        icon: 'person_outline',
        position: 66,
        subItems: [
          {
            name: 'My Profile',
            routeOrFunction: '/my-profile',
            position: 5
          },
          {
            name: 'My Timesheets',
            routeOrFunction: '/my-timesheets',
            position: 5
          },        
          {
            name: 'Out Of Office',
            routeOrFunction: '/out-of-office',
            position: 5
          },
          {
            name: 'Documents',
            routeOrFunction: '/documents',
            position: 5
          }
        ]
      },

    ]);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
  public isMessagePopupVisible: Boolean = false;
  public messageTitle: String = "System Message";
  public message: String = "";
  public showInformationIcon: Boolean = true;
  public showWarningIcon: Boolean = false;
  public messageType: DialogMessageTypes;

  public closeMessageClick() {
    this.isMessagePopupVisible = false;
  }

  public show(message: String, messageType: DialogMessageTypes) {
    this.messageTitle = "System Message";
    this.message = message;
    this.showInformationIcon = true;
    this.showWarningIcon = false;
    if (messageType == DialogMessageTypes.SystemError) {
      this.showInformationIcon = false;
      this.showWarningIcon = true;
      this.messageTitle = "System Error";
    }
    this.isMessagePopupVisible = true;
  }
}

export enum DialogMessageTypes {
  SystemError,
  Information,
}




import { Component, OnInit } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'toast-dialog',
  templateUrl: './toast-dialog.component.html',
  styleUrls: ['./toast-dialog.component.scss']
})
export class ToastDialogComponent implements OnInit {

  constructor(private toast: NgToastService) { }

  ngOnInit(): void {
  }

  show(message: string, subject: string) {
    this.toast.info({detail:subject, summary:message, duration:5000, position:"botomCenter", sticky:false});
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'incidemt-log-dialog',
  templateUrl: './incidemt-log-dialog.component.html',
  styleUrls: ['./incidemt-log-dialog.component.scss']
})
export class IncidemtLogDialogComponent {

  isIncidentLogPopupVisible:Boolean=false;
  logDatasource;
  formTitle:String="";
  
  viewLogCloseClick(){
    this.isIncidentLogPopupVisible=false;
  }
}

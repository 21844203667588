<dx-popup maxWidth="650px" maxHeight="98vh" height="auto" overflow="auto" dragEnabled="true"
  [(visible)]="isDeletePopupVisible" title="Confirm Delete">
  <div >
    <div class="dialogIcon">
      <div class="question-icon"></div>
    </div>
    <span style="display:inline" >
      <div style="font-size:15px;padding-top: 6px;">
        {{ message }}
      </div>
    </span>
  </div>
  <br>
  <dx-toolbar style="margin-top: 15px;">
    <dxi-item widget="dxButton" locateInMenu="auto" location="after">
      <dx-button (click)="confirmDeleteClicked()" type="normal" width="100"  icon="check" height="25" text="Yes">
      </dx-button>
    </dxi-item>
    <dxi-item widget="dxButton" locateInMenu="auto" location="after">
      <dx-button (click)="closeConfirmMessageClick()" type="normal" width="100"  icon="close" height="25" text="Cancel">
      </dx-button>
    </dxi-item>
  </dx-toolbar>
</dx-popup>
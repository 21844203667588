import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessingDialogComponent } from './processing-dialog/processing-dialog.component';
import { DxButtonModule, DxDataGridModule, DxFormModule, DxLoadPanelModule, DxPopupModule, DxToastModule, DxToolbarModule } from 'devextreme-angular';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ToastDialogComponent } from './toast-dialog/toast-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { IncidemtLogDialogComponent } from './incidemt-log-dialog/incidemt-log-dialog.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgToastModule } from 'ng-angular-popup';

@NgModule({
  declarations: [
    ProcessingDialogComponent,
    MessageDialogComponent,
    ConfirmDialogComponent,
    ToastDialogComponent,
    ConfirmDeleteDialogComponent,
    IncidemtLogDialogComponent,
    PdfViewerComponent
  ],
  imports: [
    CommonModule,
    DxLoadPanelModule,
    DxPopupModule,
    DxFormModule,
    DxToolbarModule,
    DxButtonModule,
    DxDataGridModule,
    PdfJsViewerModule,
    NgToastModule,
  ],
  exports:[
    ProcessingDialogComponent,
    MessageDialogComponent,
    ToastDialogComponent,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    IncidemtLogDialogComponent,
    PdfViewerComponent
  ]
})
export class CommonComponentsModule { }

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarDirective } from '../../@fury/shared/sidebar/sidebar.directive';
import { SidenavService } from './sidenav/sidenav.service';
import { filter, map, startWith } from 'rxjs/operators';
import { ThemeService } from '../../@fury/services/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@fury/utils/check-router-childs-data';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { DialogMessageTypes, MessageDialogComponent } from '../views/common-components/message-dialog/message-dialog.component';
import { ProcessingDialogComponent } from '../views/common-components/processing-dialog/processing-dialog.component';
import { ControllerService } from '../services/common/controller-service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @ViewChild(ProcessingDialogComponent, { static: true }) processingDialog: ProcessingDialogComponent;
  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;

  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  quickPanelOpen: boolean;

  sideNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );
  timeOutSeconds: number = +sessionStorage.getItem("ISTO").valueOf();// 300;
  idleSeconds: number = +sessionStorage.getItem("ISW").valueOf();// 1800;

  constructor(private sidenavService: SidenavService,
    private themeService: ThemeService,
    private controllerService: ControllerService,
    private idleService: Idle,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.timeOutSeconds = +sessionStorage.getItem("ISW").valueOf();// 300;
    this.idleSeconds = +sessionStorage.getItem("ISTO").valueOf();// 1800;

    this.idleService.setIdle(this.idleSeconds);
    this.idleService.setTimeout(this.timeOutSeconds);
    this.idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idleService.onIdleStart.subscribe(() => {
      var autoLogoutTime = new Date();
      autoLogoutTime.setMinutes(autoLogoutTime.getMinutes() + (this.timeOutSeconds / 60));
      this.messageDialog.show("You have been idle, the system will log you out in " + (this.timeOutSeconds / 60).toString() + " minutes, at " + autoLogoutTime.toLocaleTimeString('it-IT') + " Hrs.", DialogMessageTypes.Information);
    });

    this.idleService.onIdleEnd.subscribe(() => {
      this.messageDialog.isMessagePopupVisible = false;
      this.idleService.setTimeout(this.timeOutSeconds);
      this.idleService.setIdle(this.idleSeconds);
    });


    //  this.idleService.onTimeoutWarning.subscribe((secondsLeft: number) => {
    //   console.log(secondsLeft);
    //  });

    this.idleService.onTimeout.subscribe(() => {
      this.idleService.stop();
      this.controllerService.utilitiesGet("Users/LogOut?userID=" + sessionStorage.getItem("UserID") + "&type=System&save=true&save1=true&save2=true&save3=true").subscribe();
      sessionStorage.clear();
      sessionStorage.setItem("Token", "");
      this.router.navigate(['/', 'login']);
    });

    this.idleService.watch();
  }


  openQuickPanel() {
    this.quickPanelOpen = true;
  }

  openConfigPanel() {
    this.configPanel.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  openSidenav() {
    this.sidenavService.open();
  }

  ngOnDestroy(): void {

  }



}


import { Component, OnInit, ViewChild } from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @ViewChild(PdfJsViewerComponent, { static: true }) viewer: PdfJsViewerComponent;
  isReportViewerPopupVisible:boolean=false;
  reportName:String="Report";
 
  constructor() { }

  ngOnInit(): void {
    this.viewer.refresh();
  }

  closeClick(){
    this.isReportViewerPopupVisible=false;
  }
}



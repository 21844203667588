import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  isConfirmPopupVisible: Boolean=false;
  public message: String = "";
  public title:String="";

  @Output() private confirmClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

 public confirmClicked() {
    this.confirmClick.emit();
  }

  show(title:String, message: String) {
    this.message = message;
    this.title=title;
    this.isConfirmPopupVisible = true;
  }

  hide() {
    this.isConfirmPopupVisible = false;
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoggedInGuard } from './auth-guards/logged-in-auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./views/user-pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./views/user-pages/register/register.module').then(m => m.RegisterModule),
  }, 
  {
    path: '',
    component: LayoutComponent,
    children: [      
      {
        path: 'priorities',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/workflow-priorities/workflow-priorities.module').then(m => m.WorkflowPrioritiesModule),
      },
      {
        path: 'work-days',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/work-days/work-days.module').then(m => m.WorkDaysModule),
      },
      {
        path: 'escalation-groups',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/escalation-groups/escalation-groups.module').then(m => m.EscalationGroupsModule),
      },
      {
        path: 'steps',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/steps/steps.module').then(m => m.StepsModule),
      },
      {
        path: 'rejection-reasons',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/rejection-reasons/rejection-reasons.module').then(m => m.RejectionReasonsModule),
      },
      {
        path: 'workflows',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/workflows/workflows.module').then(m => m.WorkflowsModule),
      },
      {
        path: 'incident-types',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/incident-types/incident-types/incident-types.module').then(m => m.IncidentTypesModule),
      },
      {
        path: 'workflow-email-notifications',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/email-notifications/email-notifications.module').then(m => m.EmailNotificationsModule),
      },
      {
        path: 'workflow-sms-notifications',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/sms-notifications/sms-notifications.module').then(m => m.SmsNotificationsModule),
      },
      {
        path: 'workflow-settings',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/workflow/workflow-settings/workflow-settings.module').then(m => m.WorkflowSettingsModule),
      },

      {
        path: 'email-templates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/communication/email-templates/email-templates.module').then(m => m.EmailTemplatesModule),
      },
      {
        path: 'email-settings',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/communication/email-settings/email-settings.module').then(m => m.EmailSettingsModule),
      },
      {
        path: 'sms-settings',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/communication/sms-settings/sms-settings.module').then(m => m.SmsSettingsModule),
      },
      {
        path: 'sms-templates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/communication/sms-templates/sms-templates.module').then(m => m.SmsTemplatesModule),
      },
      {
        path: 'notification-templates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/communication/notification-templates/notification-templates.module').then(m => m.NotificationTemplatesModule),
      },

      {
        path: 'public-holidays',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/public-holidays/public-holidays.module').then(m => m.PublicHolidaysModule),
      },
      {
        path: 'numbering',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/numbering/numbering.module').then(m => m.NumberingModule),
      },
      {
        path: 'parameters',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/parameters/parameters.module').then(m => m.ParametersModule),
      },
      {
        path: 'organisation-details',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/organisation-details/organisation-details.module').then(m => m.OrganisationDetailsModule),
      },
      {
        path: 'organisation-set-up',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/organisation-set-up/organisation-set-up.module').then(m => m.OrganisationSetUpModule),
      },
      {
        path: 'document-types',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/document-types/document-types.module').then(m => m.DocumentTypesModule),
      },
      {
        path: 'plan-managers',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/settings/plan-managers/plan-managers.module').then(m => m.PlanManagersModule),
      },

      {
        path: 'home',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'approval-requests',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/pending-requests/pending-requests.module').then(m => m.PendingRequestsModule),
      },
      {
        path: 'open-incidents',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/open-incidents/open-incidents.module').then(m => m.OpenIncidentsModule),
      },
      {
        path: 'closed-incidents',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/closed-incidents/closed-incidents.module').then(m => m.ClosedIncidentsModule),
      },
      {
        path: 'out-of-office',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/user-out-of-office/user-out-of-office.module').then(m => m.UserOutOfOfficeModule),
      },
      {
        path: 'my-profile',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
      },
      {
        path: 'my-timesheets',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/my-timesheets/my-timesheets.module').then(m => m.MyTimesheetsModule),
      },
      {
        path: 'documents',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/user-pages/documents/documents.module').then(m => m.DocumentsModule),
      },

      {
        path: 're-assign-incidents',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/re-assign-incidents/re-assign-incidents.module').then(m => m.ReAssignIncidentsModule),
      },
      {
        path: 'manage-users',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/manage-users/manage-users.module').then(m => m.ManageUsersModule),
      },
      {
        path: 'user-profiles',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/user-profiles/user-profiles.module').then(m => m.UserProfilesModule),
      },
      {
        path: 'user-groups',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/user-groups/user-groups.module').then(m => m.UserGroupsModule),
      },
      {
        path: 'manage-documents',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/manage-documents/manage-documents.module').then(m => m.ManageDocumentsModule),
      },
      {
        path: 'audit-trail',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/system-administration/audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
      },

      {
        path: 'participants-dashboard',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/clients-dashboard/clients-dashboard.module').then(m => m.ClientsDashboardModule),
      },
      {
        path: 'employees-dashboard',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/employees-dashboard/employees-dashboard.module').then(m => m.EmployeesDashboardModule),
      },
      {
        path: 'workflows-dashboard',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/workflow/workflow.module').then(m => m.WorkflowModule),
      },
      {
        path: 'shifts-calendar',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/shifts-calendar/shifts-calendar.module').then(m => m.ShiftsCalendarModule),
      },
      {
        path: 'shifts-status',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/shift-status/shift-status.module').then(m => m.ShiftStatusModule),
      },
      {
        path: 'timesheets-dashboard',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/timesheets-dashboard/timesheets-dashboard.module').then(m => m.TimesheetsDashboardModule),
      },
      {
        path: 'system-calendar',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/system-calendar/system-calendar.module').then(m => m.SystemCalendarModule),
      },
      {
        path: 'participants-map',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/dashboards/clients-map/clients-map.module').then(m => m.ClientsMapModule),
      },

      {
        path: 'payment-rates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/payment-rates/payment-rates.module').then(m => m.PaymentRatesModule),
      },
      {
        path: 'administration-payment-rates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/administration-payment-rates/administration-payment-rates.module').then(m => m.AdministrationPaymentRatesModule),
      },
      {
        path: 'allowances',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/allowances/allowances.module').then(m => m.AllowancesModule),
      },
      {
        path: 'carer-groups',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/carer-groups/carer-groups.module').then(m => m.CarerGroupsModule),
      },    
      {
        path: 'employees',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/employees/employees.module').then(m => m.EmployeesModule),
      },     
      {
        path: 'employee-updates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/employees/employee-updates/employee-updates.module').then(m => m.EmployeeUpdatesModule),
      }, 
      {
        path: 'participant-groups',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/clients/client-groups/client-groups.module').then(m => m.ClientGroupsModule),
      },
      {
        path: 'participants',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/clients/clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: 'participant-updates',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/clients/client-updates/client-updates.module').then(m => m.ClientUpdatesModule),
      },

      {
        path: 'shift-planning',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/calendar/calendar.module').then(m => m.CalendarModule),
      },
      {
        path: 'participant-master-shifts',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/master-client-shifts/master-client-shifts.module').then(m => m.MasterClientShiftsModule),
      },
      {
        path: 'shift-times',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/shift-times/shift-times.module').then(m => m.ShiftTimesModule),
      },
      {
        path: 'roaster-parameters',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/roaster-parameters/roaster-parameters.module').then(m => m.RoasterParametersModule),
      },
      {
        path: 'roaster-communication-setup',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/communication-setup/communication-setup.module').then(m => m.CommunicationSetupModule),
      },
      {
        path: 'shifts',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/shifts/shifts.module').then(m => m.ShiftsModule),
      },
      {
        path: 'timesheets',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/timesheets/timesheets.module').then(m => m.TimesheetsModule),
      },
      {
        path: 'shift-offers',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/shift-offers/shift-offers.module').then(m => m.ShiftOffersModule),
      },
      {
        path: 'unavailability-requests',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/scheduler/unavailability-requests/unavailability-requests.module').then(m => m.UnavailabilityRequestsModule),
      },

      
      {
        path: 'timesheet-batches',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/accounting/timesheet-batches/timesheet-batches.module').then(m => m.TimesheetBatchesModule),
      },
      {
        path: 'invoices',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./views/accounting/invoices/invoices.module').then(m => m.InvoicesModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'processing-dialog',
  templateUrl: './processing-dialog.component.html',
  styleUrls: ['./processing-dialog.component.scss']
})

export class ProcessingDialogComponent implements OnInit {
  loadingVisible: Boolean = false;
  constructor() { }

  ngOnInit() {
  }

  show() {
    this.loadingVisible = true;
  }

  hide() {
    this.loadingVisible = false;
  }
}

<dx-popup maxWidth="650px" maxHeight="98vh" height="auto" overflow="auto" dragEnabled="true"
  [(visible)]="isMessagePopupVisible" [(title)]="messageTitle">
  <div>
    <div class="dialogIcon">
 <div class="information-icon" *ngIf="showInformationIcon"></div>
 <div class="error-icon"  *ngIf="showWarningIcon"></div>
    </div>
    <span style="display:inline" >
  <div style="font-size:15px;padding-top: 7px;">
    {{ message }}
  </div>
</span>
  </div>
  <br>
  <dx-toolbar style="margin-top: 15px;">
    <dxi-item widget="dxButton" locateInMenu="auto" location="after">
      <dx-button (click)="closeMessageClick()" type="normal" width="100"  icon="check" height="25" text="OK">
      </dx-button>
    </dxi-item>
  </dx-toolbar>
</dx-popup>
import { Injectable } from '@angular/core';
import 'devextreme/data/odata/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UtilitiesService } from './utilities-service';

@Injectable({
  providedIn: 'root'
})

export abstract class BaseService<T> {
  abstract route: string;
  orderBy = '';
  private get orderByQuery(): string {
    return this.orderBy === '' ? '' : '?$orderby=' + this.orderBy;
  }

  constructor(protected httpClient: HttpClient,
    private utilities: UtilitiesService) {
  }

  get(): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl + `${this.route}`, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }

  getSingle(id: Number, query = ''): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl + `${this.route}(${id})${query}`, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }

  getSingleRecordByID(query: string): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl + query, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }

  utilitiesGet(query: string): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl + `${this.route}` + query, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }

  utilitiesPost(query: string, requestBody: any): any {
    return this.httpClient.post<any>(this.utilities.baseAPIUrl + `${this.route}` + query, requestBody, {headers: new HttpHeaders().set('token', this.utilities.getToken()) });
  }

  searchNonOdata(query: string): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl + query, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }
  
  getFiltered(query=''): any {
    return this.httpClient.get<any>(this.utilities.baseAPIUrl+`${this.route}${query}`, {headers: new HttpHeaders().set('token', this.utilities.getToken() ) });
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  baseAPIUrl;
  config;
  constructor(private httpClient: HttpClient) {

  }

  public loadConfig() {
    return this.httpClient.get('/assets/AppConfig.json')
      .toPromise()
      .then((config: any) => {
        this.config = config;
        this.baseAPIUrl = this.config.apiBaseUrl;
      })
      .catch((err: any) => {
        this.baseAPIUrl = "http://localhost:44302/";
      })
  } 
}





<dx-popup width="auto" maxHeight="98vh" height="auto" overflow="auto" [dragEnabled]=true [resizeEnabled]=true
maxWidth="90%" [(visible)]=isReportViewerPopupVisible title="Report Viewer">
<div style="padding: 4px;">
    <dx-form labelLocation="left" colCount="1">
        <dxi-item itemType="group">
            <div style="border: 1px solid rgb(88, 90, 121); background: transparent;">
                <div
                    style="border-bottom: 2px solid rgb(88, 90, 121); background: transparent;padding-left: 3px; padding-top: 2px;padding-bottom: 0px; font-size: larger; background-color:rgb(88, 90, 121);">
                    <b><div style="color: white;">{{reportName}}</div></b>
                </div>
                <div style="width: 1170px; height: 700px; padding-left: 0px; padding:0px;" >
                    <ng2-pdfjs-viewer #pdfViewer  zoom="80"
                    openFile="false" find="false"></ng2-pdfjs-viewer>
                </div>
            </div>
        </dxi-item>
    </dx-form>
    <dx-toolbar style="margin-top: 4px;">
        <dxi-item widget="dxButton" locateInMenu="auto" location="center">
            <dx-button (click)="closeClick()"  width="150" height="25" text="Cancel" type="normal"
                icon="close">
            </dx-button>
        </dxi-item>
    </dx-toolbar>
</div>
</dx-popup> 
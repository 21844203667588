import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
    constructor(private route: Router) {

    }

    canActivate(): boolean {
        if (sessionStorage.getItem("Token")==null || sessionStorage.getItem("Token")==""|| sessionStorage.getItem("Token")==undefined) {
            this.route.navigate(['/', 'login']);
            sessionStorage.clear();
            return false;
        }
        else {
            return true;
        }
    }
}
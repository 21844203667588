import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

@Injectable({
    providedIn: 'root'
  })
export class ControllerService extends BaseService<String> {
    route = 'api/';
    orderBy = '';
  }
